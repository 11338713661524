.Pagination {
	display: flex;
	justify-content: space-between;
	gap: 2rem;
	margin-block-start: var(--spacing-l);
	padding-block-start: var(--spacing-l);
	border-block-start: var(--divider) var(--color-outline);
}

.Pagination a {
	text-decoration: none;
}

.Pagination span {
	text-decoration: underline;
}

.Pagination [rel="next"] {
	margin-inline-start: auto;
}
