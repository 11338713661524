.Notes-list {
	list-style: none;
	margin-block-start: 0;
	padding-inline-start: 0;
}

.Notes-item {
	margin-block-start: var(--spacing-l);
	padding-block-start: var(--spacing-l);
	border-block-start: var(--divider) var(--color-outline);
}
